export const frComponentMessages: any = {
  grid: {
    groupPanelEmpty:
      'Faites glisser un en-tête de colonne et déposez-le ici pour grouper par cette colonne',
    pagerItemsPerPage: 'éléments par page',
    pagerInfo: '{0} - {1} sur {2} éléments',
    pagerFirstPage: 'Aller à la première page',
    pagerPreviousPage: 'Aller à la page précédente',
    pagerNextPage: 'Aller à la page suivante',
    pagerLastPage: 'Aller à la dernière page',
    pagerPage: 'Page',
    pagerOf: 'de',
    filterClearButton: 'Effacer',
    filterSubmitButton: 'Filtrer',
    filterTitle: 'Filtrer',
    filterEqOperator: 'Est égal à',
    filterNotEqOperator: "N'est pas égal à",
    filterIsNullOperator: 'Est nulle',
    filterIsNotNullOperator: 'Est non nulle',
    filterIsEmptyOperator: 'Est vide',
    filterIsNotEmptyOperator: "N'est pas vide",
    filterStartsWithOperator: 'Commence par',
    filterContainsOperator: 'Contient',
    filterNotContainsOperator: 'Ne contient pas',
    filterEndsWithOperator: 'Se termine par',
    filterGteOperator: 'Est supérieur ou égal à',
    filterGtOperator: 'Est supérieur à',
    filterLteOperator: 'Est inférieur ou égal à',
    filterLtOperator: 'Est inférieur à',
    filterIsTrue: 'Est vrai',
    filterIsFalse: 'Est faux',
    filterBooleanAll: '(Tous)',
    filterAfterOrEqualOperator: 'Est postérieur ou égal à',
    filterAfterOperator: 'Est postérieur',
    filterBeforeOperator: 'Est antérieur',
    filterBeforeOrEqualOperator: 'Est antérieur ou égal à',
    noRecords: 'Aucun enregistrement disponible.'
  },
  dateinput: {
    increment: 'Augmenter la valeur',
    decrement: 'Diminuer la valeur'
  },
  calendar: {
    today: "AUJOURD'HUI"
  },
  datepicker: {
    toggleCalendar: 'Basculer le calendrier'
  },
  dropdowns: {
    nodata: 'AUCUNE DONNÉE DISPONIBLE.',
    clear: 'Effacer'
  },
  numerictextbox: {
    increment: 'Augmenter la valeur',
    decrement: 'Diminuer la valeur'
  },
  upload: {
    cancel: 'Cancel',
    clearSelectedFiles: 'Effacer',
    dropFilesHere: 'Déposer des fichiers ici pour télécharger',
    headerStatusUploaded: 'Terminé',
    headerStatusUploading: 'Téléchargement...',
    invalidFileExtension: 'Type de fichier non autorisé.',
    invalidFiles:
      'Fichier(s) invalide(s). Veuillez vérifier les exigences de téléchargement de fichier.',
    invalidMaxFileSize: 'La taille du fichier est trop grande.',
    invalidMinFileSize: 'La taille du fichier est trop petite.',
    remove: 'Retirer',
    retry: 'Réessayez',
    select: 'Sélectionnez les fichiers...',
    uploadSelectedFiles: 'Télécharger',
    total: 'Total',
    files: 'fichiers'
  },
  sortable: {
    noData: 'Aucune donnée'
  }
}
