export default {
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' }
  ],
  numeric: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterGteOperator', operator: 'gte' },
    { text: 'grid.filterLteOperator', operator: 'lte' }
  ],
  date: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
  ],
  boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
}
